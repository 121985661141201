

















































































































































































































































































































































































































































































































































import Stage from '@/components/organisms/stage/Stage.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormInputMultiLineText from '@/components/atoms/formInputMultiLineText/FormInputMultiLineText.vue';
import FormInputCount from '@/components/molecules/formInputCount/FormInputCount.vue';
import FormStep from '@/components/molecules/formStep/FormStep.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';
import FormRadioGroupTiles from '@/components/molecules/formRadioGroupTiles/FormRadioGroupTiles.vue';
import FormCheckboxList from '@/components/molecules/formCheckboxList/FormCheckboxList.vue';
import FormDropdownCheckboxList from '@/components/molecules/formDropdownCheckboxList/FormDropdownCheckboxList.vue';
import FormSelect from '@/components/molecules/formSelect/FormSelect.vue';
import { getFormStepMixin } from '@/mixins/formStepMixin';
import FormInputGeoCoordinates from '@/components/molecules/formInputGeoCoordinates/FormInputGeoCoordinates.vue';
import AppButton from '@/components/atoms/appButton/AppButton.vue';
import DocumentDownload from '@/components/atoms/documentDownload/DocumentDownload.vue';
import AppContent from '@/components/atoms/appContent/AppContent.vue';
import AppLayer from '@/components/molecules/appLayer/AppLayer.vue';
import FormFileUpload from '@/components/molecules/formFileUpload/FormFileUpload.vue';
import DatePicker from '@/components/molecules/datePicker/DatePicker.vue';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';
import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import KitchenSinkFormStep4 from '@/components/organisms/dummy/kitchenSinkFormStep4/KitchenSinkFormStep4.vue';
import { KitchenSinkForm } from '@/types/forms/dummy/kitchenSinkForm';
import { PropType } from 'vue';
import { Mkf } from '@/api/interfaces/mkf/mkf';
import { FormOption } from '@/types/forms/formOption';
import { SelectContent } from '@/api/interfaces/content/form/base/selectContent';
import { SelectSearchContent } from '@/api/interfaces/content/form/base/selectSearchContent';
import FormButton from '@/components/molecules/formButton/FormButton.vue';

export default getFormStepMixin<KitchenSinkForm, unknown, unknown>().extend({
    name: 'KitchenSinkTemplate',
    components: {
        Stage,
        FormField,
        FormBooleanCheckbox,
        FormInputText,
        FormInputMultiLineText,
        FormInputCount,
        FormStep,
        FormTextBlock,
        FormRadioGroup,
        FormRadioGroupTiles,
        FormCheckboxList,
        FormDropdownCheckboxList,
        FormInputGeoCoordinates,
        AppButton,
        DocumentDownload,
        FormSelect,
        AppContent,
        AppLayer,
        FormFileUpload,
        DatePicker,
        FieldWithInfoIcon,
        FunctionalityButton,
        KitchenSinkFormStep4,
        FormButton,
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        mkf: {
            type: Object as PropType<Mkf>,
            required: true,
        },
    },
    data() {
        return {
            showLayer: false,
            step4: {
                addMoreButtonValue: 'add' as FunctionalityButtonValueType,
            },
            contentStep1SelectFieldMsb: {
                label: 'MSB',
                placeholder: 'Bitte wählen',
                search: {
                    placeholder: 'Filter',
                    noResults: 'Keine Ergebnisse',
                },
                options: {},
            } as SelectContent & SelectSearchContent,
        };
    },
    methods: {
        resetValidation(): void {
            this.validations?.$reset();
        },
        validate(): void {
            this.validations?.$touch();
        },
        handleAddMoreButtonClick(value: FunctionalityButtonValueType): void {
            if (value === 'add') {
                this.step4.addMoreButtonValue = 'remove';
            } else {
                this.step4.addMoreButtonValue = 'add';
            }
        },
    },
    computed: {
        geoCoordinatesContent() {
            return {
                label: 'Geografische B Koordinaten',
                info:
                    'Geographische Daten werden in sechzig Minuten, 1 Minute wiederum in 60 Sekunden angegeben (Beispiel 54°47\'19,96")',
                degrees: {
                    placeholder: '53',
                    unit: '°',
                },
                minutes: {
                    placeholder: '6',
                    unit: "'",
                },
                seconds: {
                    placeholder: '43,4',
                    unit: '"',
                },
            };
        },
    },
    watch: {
        // this has to be handled in rules.
        'step4.addMoreButtonValue': {
            handler: function (newValue: FunctionalityButtonValueType) {
                this.model.step4.secondary.disabled = newValue === 'add';
            },
            immediate: true,
        },
    },
    created() {
        const msb = this.mkf?.MSB;
        if ((msb?.size ?? 0) > 0) {
            const values = msb?.values ?? {};
            this.model.step1.selectFieldMsb.options = [];
            this.contentStep1SelectFieldMsb.options = {};
            for (var key in values) {
                const value = values[key];
                if (
                    this.model.step1.selectFieldMsb.options.some(
                        (o) => o.key == value,
                    )
                ) {
                    // key already in list, skip
                    continue;
                }
                this.model.step1.selectFieldMsb.options.push(
                    new FormOption({
                        key: value,
                    }),
                );
                this.contentStep1SelectFieldMsb.options[value] = {
                    label: value,
                };
            }
            // sort alphabetically
            this.model.step1.selectFieldMsb.options.sort((a, b) => {
                const akl = a.key.toLowerCase();
                const bkl = b.key.toLowerCase();
                if (akl < bkl) {
                    return -1;
                }
                if (akl > bkl) {
                    return 1;
                }
                return 0;
            });
        }
    },
});
