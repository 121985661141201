var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormStep',{attrs:{"step":4,"identifier":"step4","content":{},"heading":"Funktionsbutton","state":"current"}},[_c('FormRow',[_c('span',{staticClass:"text-primary text-xl block mb-2"},[_vm._v(" Funktionsbutton ohne FormList ")])]),_c('FormField',{attrs:{"model":_vm.model.primary,"content":{
            label: 'Primäres Feld',
            info: 'Lorem ipsum dolor sit amet',
        },"validations":_vm.validations.primary}},[_c('FormInputText',{attrs:{"model":_vm.model.primary,"content":{ placeholder: 'Primary' },"validations":_vm.validations.primary,"show-info-icon":""}})],1),_c('FormField',{attrs:{"model":_vm.model.secondary,"content":{ label: 'Sekundäres Feld' },"validations":_vm.validations.secondary}},[_c('FormInputText',{attrs:{"model":_vm.model.secondary,"content":{ placeholder: 'Secondary' },"validations":_vm.validations.secondary}})],1),_c('FunctionalityButton',{attrs:{"content":{
            addLabel: 'Weitere Felder bearbeiten',
            removeLabel: 'Weniger Felder bearbeiten',
            info: 'Aktiviert das zweite Feld',
        },"value":_vm.addMoreButtonValue,"show-info-icon":""},on:{"click":_vm.handleAddMoreButtonClick}}),_c('FormRow',[_c('span',{staticClass:"text-primary text-xl block mb-2"},[_vm._v(" Funktionsbutton mit beschränkter FormList ")])]),_c('KitchenSinkUserFormList',{attrs:{"model":_vm.model.users,"validations":_vm.validations.users}}),_c('FormRow',[_c('span',{staticClass:"text-primary text-xl block mb-2"},[_vm._v(" Funktionsbutton mit FormList ")])]),_c('KitchenSinkPlantFormList',{attrs:{"model":_vm.model.plants,"validations":_vm.validations.plants}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }