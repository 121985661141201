var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Stage',{staticClass:"mb-4 md:mb-8 lg:mb-10 xl:mb-12",attrs:{"icon":"formular","heading":"Kitchen Sink","content":"Für Dev- und Test-Zwecke sind alle Elemente hier aufgelistet."}}),_c('div',{staticClass:"container mb-4 md:mb-8 lg:mb-10 xl:mb-12"},[_c('FormStep',{attrs:{"step":1,"identifier":"step1","content":{},"heading":"First Component","expanded":true,"state":"current"}},[_c('FormTextBlock',{attrs:{"model":_vm.model.step1.textBlock,"content":{
                    text:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum nunc, aliquet <strong>et finibus eget</strong>, ornare porta eros. Sed consequat, quam a porta lacinia, massa lectus molestie turpis, et maximus mauris felis ut est. Proin malesuada maximus dapibus. Duis sit amet luctus lectus, id interdum turpis. Duis sollicitudin orci enim, id pharetra nulla gravida et. Sed pellentesque purus leo, ac placerat dui gravida a. Etiam varius elementum nisi quis vulputate. Morbi molestie sollicitudin nulla, vel scelerisque elit consectetur a. Aenean at eros et ipsum tincidunt porttitor eu et tortor. Nullam congue feugiat molestie. Duis metus enim, consequat venenatis laoreet a, rhoncus a lacus.',
                }}}),_c('FormField',{attrs:{"model":_vm.model.step1.requiredField,"content":{
                    label: 'Pflichtfeld-Label',
                    info:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum nunc, aliquet <strong>et finibus eget</strong>, ornare porta eros. Sed consequat, quam a porta lacinia, massa lectus molestie turpis, et maximus mauris felis ut est. Proin malesuada maximus dapibus. Duis sit amet luctus lectus, id interdum turpis. Duis sollicitudin orci enim, id pharetra nulla gravida et.',
                },"validations":_vm.validations.step1.requiredField}},[_c('FormInputText',{attrs:{"model":_vm.model.step1.requiredField,"content":{ placeholder: 'Pflichtfeld-Label' },"validations":_vm.validations.step1.requiredField,"show-info-icon":""}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.multiLineField,"content":{ label: 'Multi Line Text Label' },"validations":_vm.validations.step1.multiLineField,"label-align-top":""}},[_c('FormInputMultiLineText',{attrs:{"model":_vm.model.step1.multiLineField,"content":{
                        placeholder: 'Multi Line Text Placeholder',
                    },"validations":_vm.validations.step1.multiLineField}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.numberField,"content":{ label: 'Zahlenfeld-Label' },"validations":_vm.validations.step1.numberField}},[_c('FormInputText',{attrs:{"model":_vm.model.step1.numberField,"content":{
                        placeholder: 'Zahlenfeld-Label',
                        info:
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum nunc, aliquet <strong>et finibus eget</strong>, ornare porta eros. Sed consequat, quam a porta lacinia, massa lectus molestie turpis, et maximus mauris felis ut est. Proin malesuada maximus dapibus. Duis sit amet luctus lectus, id interdum turpis. Duis sollicitudin orci enim, id pharetra nulla gravida et. Sed pellentesque purus leo, ac placerat dui gravida a. Etiam varius elementum nisi quis vulputate.',
                    },"validations":_vm.validations.step1.numberField,"show-info-icon":""}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.wideField,"content":{ label: 'Ein superbreites Feld' },"validations":_vm.validations.step1.wideField,"align-field":"belowLabel"}},[_c('FormInputText',{attrs:{"model":_vm.model.step1.wideField,"content":{ placeholder: 'Platzhalter' },"validations":_vm.validations.step1.wideField}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.innerLabelField,"content":{
                    label: 'Feld mit innerem Label',
                },"validations":_vm.validations.step1.innerLabelField}},[_c('FormInputText',{attrs:{"model":_vm.model.step1.innerLabelField,"content":{
                        placeholder: 'konventioneller Platzhalter',
                        innerLabel: 'inneres Label',
                    },"validations":_vm.validations.step1.innerLabelField}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.geoCoordinatesField,"content":{ label: 'Geo-Koordinaten-Feld' },"validations":_vm.validations.step1.geoCoordinatesField}},[_c('FormInputGeoCoordinates',{attrs:{"model":_vm.model.step1.geoCoordinatesField,"content":_vm.geoCoordinatesContent,"validations":_vm.validations.step1.geoCoordinatesField}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.checkboxField1,"content":{ label: 'Eine Checkbox' },"validations":_vm.validations.step1.checkboxField1,"label":"Eine Checkbox"}},[_c('FormBooleanCheckbox',{attrs:{"model":_vm.model.step1.checkboxField1,"content":{
                        label: 'Checkbox zum anhaken oder abhaken',
                    },"validations":_vm.validations.step1.checkboxField1}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.checkboxField2,"content":{},"validations":_vm.validations.step1.checkboxField2,"align-field":"singleLine"}},[_c('FormBooleanCheckbox',{attrs:{"model":_vm.model.step1.checkboxField2,"content":{
                        label:
                            'Checkbox über die volle Breite zum anhaken oder abhaken',
                    },"validations":_vm.validations.step1.checkboxField2}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.selectField,"content":{ label: 'Dropdown' },"validations":_vm.validations.step1.selectField}},[_c('FormSelect',{attrs:{"model":_vm.model.step1.selectField,"content":{
                        placeholder: 'Bitte wählen',
                        options: {
                            opt1: { label: 'Option eins' },
                            opt2: { label: 'Option zwei' },
                            opt3: { label: 'Option drei' },
                        },
                    },"validations":_vm.validations.step1.selectField}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.selectFieldSingleOption,"content":{
                    label: 'Dropdown (single option)',
                    info:
                        'Hat das Dropdown nur eine Option, wird diese vorausgewählt und das Dropdown disabled.',
                },"validations":_vm.validations.step1.selectFieldSingleOption}},[_c('FormSelect',{attrs:{"model":_vm.model.step1.selectFieldSingleOption,"content":{
                        placeholder: 'Bitte wählen',
                        options: {
                            opt1: { label: 'Option eins' },
                        },
                        info:
                            'Hat das Dropdown nur eine Option, wird diese vorausgewählt.',
                    },"validations":_vm.validations.step1.selectFieldSingleOption,"show-info-icon":""}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.selectFieldSearch,"content":{ label: 'Dropdown (search)' },"validations":_vm.validations.step1.selectFieldSearch}},[_c('FormSelect',{attrs:{"model":_vm.model.step1.selectFieldSearch,"select-customized":true,"select-customized-show-search":true,"content":{
                        placeholder: 'Bitte wählen',
                        search: {
                            placeholder: 'Filter',
                            noResults: 'Keine Ergebnisse',
                        },
                        options: {
                            opt1: { label: 'Option eins' },
                            opt2: { label: 'Option zwei' },
                            opt3: { label: 'Option drei' },
                            opt4: { label: 'Option vier' },
                            opt5: { label: 'Option fünf' },
                            opt6: { label: 'Option sechs' },
                            opt7: { label: 'Option sieben' },
                            opt8: { label: 'Option acht' },
                            opt9: { label: 'Option neun' },
                        },
                        info:
                            'Es kann eine Custom Dropdown Implementierung mit optionaler Suche aktiviert werden.',
                    },"validations":_vm.validations.step1.selectFieldSearch,"show-info-icon":""}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.selectFieldMsb,"content":_vm.contentStep1SelectFieldMsb,"validations":_vm.validations.step1.selectFieldMsb}},[_c('FormSelect',{attrs:{"model":_vm.model.step1.selectFieldMsb,"select-customized":true,"select-customized-show-search":true,"content":_vm.contentStep1SelectFieldMsb,"validations":_vm.validations.step1.selectFieldMsb,"show-info-icon":""}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.fileUpload,"content":{
                    label: 'Upload',
                    subLabel: '(.pdf oder .jpg, bis 5 MB)',
                },"validations":_vm.validations.step1.fileUpload}},[_c('FormFileUpload',{attrs:{"model":_vm.model.step1.fileUpload,"configuration":_vm.configuration.step1.fileUpload,"application-id":_vm.model.applicationId,"validations":_vm.validations.step1.fileUpload}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.datePickerField,"content":{ label: 'Datepicker' },"validations":_vm.validations.step1.datePickerField}},[_c('DatePicker',{attrs:{"model":_vm.model.step1.datePickerField,"content":{ placeholder: 'Datepicker' },"validations":_vm.validations.step1.datePickerField,"show-today-button":false}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.datePickerRangeField,"content":{ label: 'Datepicker Range' },"validations":_vm.validations.step1.datePickerRangeField}},[_c('DatePicker',{attrs:{"model":_vm.model.step1.datePickerRangeField,"content":{ placeholder: 'Datepicker Range' },"validations":_vm.validations.step1.datePickerRangeField,"show-today-button":false}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.countField,"content":{ label: 'Count-Feld' },"validations":_vm.validations.step1.countField}},[_c('FormInputCount',{attrs:{"model":_vm.model.step1.countField,"content":{ placeholder: 'Count' },"validations":_vm.validations.step1.countField}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.documentDownload,"content":{ label: 'Label Download' }}},[_c('DocumentDownload',{attrs:{"model":_vm.model.step1.documentDownload,"document-id":"mainDocuments","application-id":_vm.model.applicationId,"content":{
                        info:
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum nunc, aliquet <strong>et finibus eget</strong>, ornare porta eros. Sed consequat, quam a porta lacinia, massa lectus molestie turpis, et maximus mauris felis ut est. Proin malesuada maximus dapibus. Duis sit amet luctus lectus, id interdum turpis. Duis sollicitudin orci enim, id pharetra nulla gravida et. Sed pellentesque purus leo, ac placerat dui gravida a. Etiam varius elementum nisi quis vulputate.',
                    },"show-info-icon":""}})],1),_c('FormField',{attrs:{"model":_vm.model.step1.formButton,"content":{ label: 'Ein Button Feld' }}},[_c('FormButton',{attrs:{"model":_vm.model.step1.formButton,"content":{
                        link: {
                            text: 'Button Text',
                            url: 'https://www.google.de',
                            target: '_blank',
                        },
                        info:
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum nunc, aliquet <strong>et finibus eget</strong>, ornare porta eros. Sed consequat, quam a porta lacinia, massa lectus molestie turpis, et maximus mauris felis ut est. Proin malesuada maximus dapibus. Duis sit amet luctus lectus, id interdum turpis. Duis sollicitudin orci enim, id pharetra nulla gravida et. Sed pellentesque purus leo, ac placerat dui gravida a. Etiam varius elementum nisi quis vulputate.',
                    },"show-info-icon":""}})],1)],1),_c('FormStep',{attrs:{"step":2,"identifier":"step2","content":{},"heading":"Radio-Button-Liste","expanded":true,"state":"current"}},[_c('FormField',{attrs:{"model":_vm.model.step2.selected,"content":{ label: 'Welche Option ist korrekt?' },"validations":_vm.validations.step2.selected,"align-field":"belowLabel"}},[_c('FormRadioGroup',{attrs:{"model":_vm.model.step2.selected,"content":{
                        options: {
                            opt1: {
                                label:
                                    'Option1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                            },
                            opt2: {
                                label:
                                    'Option2 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                            },
                            opt3: {
                                label:
                                    'Option3 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                            },
                        },
                    },"validations":_vm.validations.step2.selected,"orientation":"vertical"}})],1),_c('FormField',{attrs:{"model":_vm.model.step2.selectedTiles,"content":{
                    label: 'Worüber möchten Sie mehr erfahren?',
                    info:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum nunc, aliquet <strong>et finibus eget</strong>, ornare porta eros. Sed consequat, quam a porta lacinia, massa lectus molestie turpis, et maximus mauris felis ut est. Proin malesuada maximus dapibus. Duis sit amet luctus lectus, id interdum turpis. Duis sollicitudin orci enim, id pharetra nulla gravida et. Sed pellentesque purus leo, ac placerat dui gravida a. Etiam varius elementum nisi quis vulputate.',
                },"validations":_vm.validations.step2.selectedTiles,"align-field":"belowLabel","full-width":"","show-info-icon":""}},[_c('FormRadioGroupTiles',{attrs:{"model":_vm.model.step2.selectedTiles,"content":{
                        options: {
                            netzanschluss: {
                                label: 'Netzanschluss',
                                image: {
                                    path:
                                        'https://www.ewe-netz.de/~/media/ewe-netz/teaser-bilder/einspeiser/ventile-ewe-netz_440x285.jpg?h=285&la=de-DE',
                                },
                            },
                            einspeiseleistung: {
                                label: 'Einspeiseleistung',
                                image: {
                                    path:
                                        'https://www.ewe-netz.de/~/media/ewe-netz/teaser-bilder/einspeiser/windraeder-unwetter-ewe-netz_440x285.jpg?h=285&la=de-DE',
                                },
                            },
                            verguetungen: {
                                label: 'Vergütungen',
                                image: {
                                    path:
                                        'https://www.ewe-netz.de/~/media/ewe-netz/teaser-bilder/einspeiser/strom-preis-einspeiser_440x285.jpg?h=285&la=de-DE',
                                },
                            },
                        },
                    },"validations":_vm.validations.step2.selectedTiles}})],1)],1),_c('FormStep',{attrs:{"step":3,"identifier":"step3","content":{},"heading":"Checkbox-Liste","expanded":true,"state":"current"}},[_c('FormField',{attrs:{"model":_vm.model.step3.checkedOptions1,"content":{
                    label: 'Woran sind Sie interessiert?',
                },"validations":_vm.validations.step3.checkedOptions1,"align-field":"belowLabel"}},[_c('FormCheckboxList',{attrs:{"model":_vm.model.step3.checkedOptions1,"content":{
                        options: {
                            opt1: { label: 'Netzanschluss' },
                            opt2: { label: 'Einspeiseleistung' },
                            opt3: { label: 'Vergütungen' },
                        },
                    },"validations":_vm.validations.step3.checkedOptions1}})],1),_c('FormField',{attrs:{"model":_vm.model.step3.checkedOptions2,"content":{ label: 'Wie haben Sie von uns erfahren?' },"validations":_vm.validations.step3.checkedOptions2,"align-field":"belowLabel"}},[_c('FormCheckboxList',{attrs:{"model":_vm.model.step3.checkedOptions2,"content":{
                        options: {
                            friends: {
                                label:
                                    'Empfehlung durch Freunde oder Bekannten Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                            },
                            internet: {
                                label:
                                    'Werbung im Internet Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                            },
                            television: {
                                label:
                                    'Werbung im Fernsehen Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                            },
                            radio: {
                                label:
                                    'Werbung im Radio Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                            },
                        },
                    },"validations":_vm.validations.step3.checkedOptions2,"orientation":"vertical"}})],1),_c('FormField',{attrs:{"model":_vm.model.step3.dropdownCheckedOptions,"content":{ label: 'Wie haben Sie von uns erfahren?' },"validations":_vm.validations.step3.dropdownCheckedOptions,"align-field":"belowLabel"}},[_c('FormDropdownCheckboxList',{attrs:{"model":_vm.model.step3.dropdownCheckedOptions,"content":{
                        placeholder: 'Dropdown CheckboxList',
                        options: {
                            friends: {
                                label:
                                    'Empfehlung durch Freunde oder Bekannten.',
                            },
                            internet: {
                                label: 'Werbung im Internet.',
                            },
                            television: {
                                label: 'Werbung im Fernsehen.',
                            },
                            radio: {
                                label: 'Werbung im Radio.',
                            },
                        },
                    },"validations":_vm.validations.step3.dropdownCheckedOptions}})],1)],1),_c('KitchenSinkFormStep4',{attrs:{"model":_vm.model.step4,"validations":_vm.validations.step4}}),_c('div',{staticClass:"container flex justify-center"},[_c('AppButton',{staticClass:"mx-2",attrs:{"label":"reset validation"},on:{"button-click":_vm.resetValidation}}),_c('AppButton',{staticClass:"mx-2",attrs:{"label":"validate"},on:{"button-click":_vm.validate}}),_c('AppButton',{staticClass:"mx-2",attrs:{"label":"show layer","character":"none"},on:{"button-click":function($event){_vm.showLayer = true}}})],1)],1),_c('AppLayer',{attrs:{"show":_vm.showLayer},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('AppContent',{attrs:{"use-large-font":true,"content":"Sind Sie sicher? Mit Speichern der Änderung werden gegebenfalls sämtliche nachfolgende Eingaben gelöscht"}})]},proxy:true},{key:"action1",fn:function(){return [_c('AppButton',{attrs:{"label":"Ja, Ich möchte speichern"},on:{"button-click":function($event){_vm.showLayer = false}}})]},proxy:true},{key:"action2",fn:function(){return [_c('AppButton',{attrs:{"label":"Abbrechen","character":"cross"},on:{"button-click":function($event){_vm.showLayer = false}}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }