

























import FormField from '@/components/molecules/formField/FormField.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import KitchenSinkPlantFormGroup from '@/components/organisms/dummy/kitchenSinkPlantFormGroup/KitchenSinkPlantFormGroup.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { KitchenSinkPlantListType } from '@/types/forms/dummy/formGroups/kitchenSinkPlantList';
import type { KitchenSinkPlantFormGroup as KitchenSinkPlantFormGroupType } from '@/types/forms/dummy/formGroups/kitchenSinkPlantFormGroup';

export default getFormInputMixin<KitchenSinkPlantListType, unknown>().extend({
    name: 'KitchenSinkPlantList',
    components: {
        FormField,
        FormInputText,
        FunctionalityButton,
        KitchenSinkPlantFormGroup,
    },
    data() {
        return {
            functionalityButtonValue: 'add' as FunctionalityButtonValueType,
        };
    },
    methods: {
        handleFunctionalityButtonClick(
            value: FunctionalityButtonValueType,
        ): void {
            if (value === 'add') {
                this.model.createItem();
            }
        },
        handlePlantFunctionalityButtonClick(
            value: FunctionalityButtonValueType,
            plant: KitchenSinkPlantFormGroupType,
        ): void {
            if (value === 'remove') {
                this.model.removeItem(plant);
            }
        },
    },
});
