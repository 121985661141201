



























import FormField from '@/components/molecules/formField/FormField.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FunctionalityButton from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import type { KitchenSinkUserFormGroup } from '@/types/forms/dummy/formGroups/kitchenSinkUserFormGroup';

export default getFormInputMixin<KitchenSinkUserFormGroup, unknown>().extend({
    name: 'KitchenSinkUserFormGroup',
    components: {
        FormField,
        FormInputText,
        FunctionalityButton,
    },
});
