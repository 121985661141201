














import Vue from 'vue';
import KitchenSinkTemplate from '@/components/templates/dummy/kitchenSinkTemplate/KitchenSinkTemplate.vue';
import { modelService } from '@/services/form/modelService';
import { rules } from '@/services/form/rules';
import { contentServices } from '@/services/contentService';
import { appConfiguration } from '@/services/form/appConfiguration';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';
import { KitchenSinkForm } from '@/types/forms/dummy/kitchenSinkForm';
import { Validation } from 'vuelidate';
import { ValidationProperties } from 'vue/types/vue';
import { mkfServices } from '@/services/mkfService';
import { Mkf } from '@/api/interfaces/mkf/mkf';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import { SpinnerState } from '@/enums/spinnerState';
import router from '@/router';

export default Vue.extend({
    name: 'KitchenSink',
    components: {
        KitchenSinkTemplate,
        SpinnerArea,
    },
    data() {
        return {
            spinner: SpinnerState.opaque,
            mkf: null as Mkf | null,
            contentLoaded: false,
        };
    },
    computed: {
        model(): KitchenSinkForm {
            return modelService.model.kitchenSink;
        },
        content(): unknown {
            return {};
        },
        rules(): () => void {
            return rules.kitchenSink;
        },
        validations(): Validation & ValidationProperties<unknown> {
            return modelService.validations.kitchenSink;
        },
        kitchenSinkContent(): unknown | null {
            return contentServices.form.content ? {} : null;
        },
        kitchenSinkConfiguration(): unknown {
            return {
                step1: {
                    fileUpload: {
                        configurations: [
                            {
                                type: 'fileUploadConfiguration',
                                scId: 'kitchenSinkFileUploadGuid',
                                validations: {
                                    fileTypes: ['pdf'],
                                    maxFileSize: 5242880,
                                },
                            },
                        ],
                    } as FormStepConfiguration,
                },
            };
        },
    },
    async mounted(): Promise<void> {
        try {
            this.mkf = await mkfServices.mkf.requestDataOnce();
            this.contentLoaded = !!this.mkf;
        } catch (error) {
            router.push('/fehler');
        }
        appConfiguration.fileUploadConfigurations[
            'kitchenSinkFileUploadGuid'
        ] = {
            fileTypes: ['pdf'],
            maxFileSize: 5242880,
        };
        this.spinner = SpinnerState.off;
    },
});
