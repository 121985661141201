





















import FormField from '@/components/molecules/formField/FormField.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import KitchenSinkUserFormGroup from '@/components/organisms/dummy/kitchenSinkUserFormGroup/KitchenSinkUserFormGroup.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { KitchenSinkUserListType } from '@/types/forms/dummy/formGroups/kitchenSinkUserList';

export default getFormInputMixin<KitchenSinkUserListType, unknown>().extend({
    name: 'KitchenSinkUserFormList',
    components: {
        FormField,
        FormInputText,
        FunctionalityButton,
        KitchenSinkUserFormGroup,
    },
    data() {
        return {
            functionalityButtonValue: 'add' as FunctionalityButtonValueType,
        };
    },
    methods: {
        handleFunctionalityButtonClick(
            value: FunctionalityButtonValueType,
        ): void {
            if (value === 'add') {
                this.model.createItem();
                this.functionalityButtonValue = 'remove';
            } else {
                this.model.pop();
                this.functionalityButtonValue = 'add';
            }
        },
    },
});
