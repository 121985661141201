





































import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import type { KitchenSinkPlantFormGroup } from '@/types/forms/dummy/formGroups/kitchenSinkPlantFormGroup';

export default getFormInputMixin<KitchenSinkPlantFormGroup, unknown>().extend({
    name: 'KitchenSinkPlantFormGroup',
    components: {
        FormBooleanCheckbox,
        FormField,
        FormInputText,
        FunctionalityButton,
    },
    data() {
        return {
            functionalityButtonValue: 'remove' as FunctionalityButtonValueType,
        };
    },
});
