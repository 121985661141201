






































































import FormField from '@/components/molecules/formField/FormField.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormStep from '@/components/molecules/formStep/FormStep.vue';
import FormRow from '@/components/molecules/formRow/FormRow.vue';
import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import KitchenSinkPlantFormList from '@/components/organisms/dummy/kitchenSinkPlantFormList/KitchenSinkPlantFormList.vue';
import KitchenSinkUserFormList from '@/components/organisms/dummy/kitchenSinkUserFormList/KitchenSinkUserFormList.vue';
import { getFormStepMixin } from '@/mixins/formStepMixin';
import type { KitchenSinkFormStep4 } from '@/types/forms/dummy/kitchensinkFormStep4';

export default getFormStepMixin<
    KitchenSinkFormStep4,
    unknown,
    unknown
>().extend({
    name: 'KitchenSinkFormStep4',
    components: {
        FormField,
        FormInputText,
        FormRow,
        FormStep,
        FunctionalityButton,
        KitchenSinkPlantFormList,
        KitchenSinkUserFormList,
    },
    data() {
        return {
            addMoreButtonValue: 'add' as FunctionalityButtonValueType,
        };
    },
    methods: {
        handleAddMoreButtonClick(value: FunctionalityButtonValueType): void {
            if (value === 'add') {
                this.addMoreButtonValue = 'remove';
            } else {
                this.addMoreButtonValue = 'add';
            }
        },
    },
    watch: {
        // this has to be handled in rules.
        addMoreButtonValue: {
            handler: function (newValue: FunctionalityButtonValueType) {
                this.model.secondary.disabled = newValue === 'add';
            },
            immediate: true,
        },
    },
});
